@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo-header-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logo-header-first {
  font-family: 'VT323', monospace;
  position: relative;
  z-index: 3;
}

.logo-header-second {
  font-family: 'VT323', monospace;
  position: relative;
  top: -125px;
  left: 3px;
  z-index: 2;
}

.logo-header-third {
  font-family: 'VT323', monospace;
  position: relative;
  top: -255px;
  left: -3px;
  z-index: 1;
}
